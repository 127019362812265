export const YoniDay = () => {
  return (
    <div >
      <iframe
        src="https://yoni-heilung-8ypfbj9.gamma.site"
              style={{
          width: "100%",
                  height: "100vh",
          border: "none",
                  overflow: "hidden",
                  scrollbarWidth: 'none'
        }}
      ></iframe>
     </div>
  );
};


