import React, {useEffect, useRef, useState} from "react";
import "../styles/feedback.scss";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../styles/gallery.scss'

const images = [
    {
        original: require('../imgs/heilkreis1.png'),
        thumbnail: require('../imgs/heilkreis1.png'),
        link: "https://t.me/joannacwalina",

    },
    {
        original: require('../imgs/heilkreis2.png'),
        thumbnail: require('../imgs/heilkreis2.png'),
        link: "https://t.me/joannacwalina",
    },
    {
        original: require('../imgs/yoniDay1.png'),
        thumbnail: require('../imgs/yoniDay1.png'),
        link: "https://joannacwalina.com/yoni-heilung",
    },
    {
        original: require('../imgs/yoniDay2.png'),
        thumbnail: require('../imgs/yoniDay2.png'),
        link: "https://joannacwalina.com/yoni-heilung",
    },

];

const Gallery = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleImageClick = () => {
        const image = images[currentIndex];
        if (image.link) {
            window.open(image.link, "_blank"); // Opens external link in a new tab
        }
    };

    return (
        <div className=" section kundenfeedback">
            <h2>Events</h2>
            <ImageGallery items={images} showPlayButton={false}    onSlide={(index) => setCurrentIndex(index)}
                          onClick={handleImageClick}  />
        </div>
    );
};

export default Gallery;
